<script lang="ts">
  import { slide } from 'svelte/transition';
  
  let activeStep: number|null = 0; // Start with step 2 open as shown in the image
  
  const steps = [
    {
      number: '01',
      title: 'Share business needs',
      content: 'Share your current business needs and what meetings you are open to. Review pitch requests. Take a meeting only when it\'s relevant and get compensated for your time if you choose so.'
    },
    {
      number: '02',
      title: 'Set up a fee if you\'d like',
      content: 'Decide if you want to be compensated for the time you spend on a meeting. Setting up even a small fee will help you ensure you\'re meeting with only people who value your time.'
    },
    {
      number: '03',
      title: 'Review meeting requests',
      content: 'Review and evaluate incoming meeting requests based on your criteria and availability.'
    },
    {
      number: '04',
      title: 'Accept relevant ones',
      content: 'Choose the meetings that align with your goals and schedule.'
    },
    {
      number: '05',
      title: 'Get on a call',
      content: 'Connect with your matched meeting participants virtually or in person.'
    }
  ];

  function toggleStep(index: number) {
    activeStep = activeStep === index ? null : index;
  }
</script>

<section class="py-24 bg-white">
  <div class="container mx-auto px-4 max-w-4xl">
    <div class="text-center mb-16">
      <h2 class="text-4xl md:text-5xl font-bold mb-6">How It Works</h2>
      <p class="text-[#8a8c94] text-lg max-w-2xl mx-auto">
        Share your current business needs and what meetings you are open to. Review pitch requests. Take a meeting only when it's relevant and get compensated for your time if you choose so.
      </p>
    </div>

    <div class="space-y-4">
      {#each steps as step, i}
        <div class="border border-[#C6C8CF] rounded-2xl bg-white shadow-sm">
          <button
            class="w-full px-8 py-6 flex items-center justify-between hover:bg-gray-50 transition-colors rounded-2xl {activeStep === i ? 'rounded-b-none' : ''}"
            on:click={() => toggleStep(i)}
            aria-expanded={activeStep === i}
          >
            <div class="flex items-center gap-6">
              <span class="text-[#8a8c94] text-2xl font-light">{step.number}</span>
              <h3 class="text-xl font-semibold text-left">{step.title}</h3>
            </div>
            <svg
              class="w-6 h-6 transform transition-transform duration-200 {activeStep === i ? 'rotate-180' : ''}"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path stroke-linecap="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
          </button>

          {#if activeStep === i}
            <div transition:slide={{ duration: 300 }} class="p-8 bg-[#C6C8CF]/20">
              <div class="flex flex-col md:flex-row gap-8 items-start">
                <p class="text-[#43444D] text-lg md:flex-1">
                  {step.content}
                </p>
                {#if step.image}
                  <div class="md:flex-1">
                    <img
                      src={step.image || "/placeholder.svg"}
                      alt="Step illustration"
                      class="rounded-xl w-full"
                    />
                  </div>
                {/if}
              </div>
            </div>
          {/if}
        </div>
      {/each}
    </div>
  </div>
</section>

<style>
  button {
    -webkit-tap-highlight-color: transparent;
  }
  
  /* Add smooth transition for hover states */
  button:focus-visible {
    outline: 2px solid #536aff;
    outline-offset: -2px;
  }
</style>