<script lang="ts">
  import { fade } from 'svelte/transition';
  
  const features = [
    {
      title: 'Email & Browser Extension',
      description: 'On average, business executives spend 1,000+ hours per year in meetings from external sales outreach. AddMeetings cuts the noise and ensures only the right ones make it to their calendar.',
      comingSoon: true
    },
    {
      title: 'AI Meeting Note-taker',
      description: 'On average, business executives spend 1,000+ hours per year in meetings from external sales outreach. AddMeetings cuts the noise and ensures only the right ones make it to their calendar.',
      comingSoon: true
    },
    {
      title: 'Calendar Integration',
      description: 'On average, business executives spend 1,000+ hours per year in meetings from external sales outreach. AddMeetings cuts the noise and ensures only the right ones make it to their calendar.',
      comingSoon: true
    },
    {
      title: 'Meeting Feedback',
      description: 'On average, business executives spend 1,000+ hours per year in meetings from external sales outreach. AddMeetings cuts the noise and ensures only the right ones make it to their calendar.',
      comingSoon: true
    }
  ];
  
  let hoveredFeature: number|null = null;
</script>

<section class="bg-[#070811] py-24">
  <div class="container mx-auto px-4">
    <h2 class="text-center text-4xl md:text-5xl lg:text-6xl font-bold mb-16">
      <span class="text-white">Coming</span>
      <span class="text-[#536aff]">Soon.</span>
    </h2>
    
    <div class="grid md:grid-cols-2 gap-6 max-w-5xl mx-auto">
      {#each features as feature, i}
        <div role="presentation"
          class="group relative p-8 rounded-2xl bg-[#12131a] border border-gray-800 hover:border-gray-700 transition-colors duration-300"
          on:mouseenter={() => hoveredFeature = i}
          on:mouseleave={() => hoveredFeature = null}
        >
          <div class="flex items-start justify-between gap-4">
            <div>
              <h3 class="text-white text-2xl font-semibold mb-4">
                {feature.title}
              </h3>
              <p class="text-[#8a8c94] leading-relaxed">
                {feature.description}
              </p>
            </div>
            
            <!-- Toggle/Coming Soon Indicator -->
            <div 
              class="shrink-0 w-12 h-7 rounded-full bg-gray-800 group-hover:bg-gray-700 transition-colors duration-300 relative"
            >
              <div 
                class="absolute top-1.5 left-1.5 w-4 h-4 rounded-full bg-gray-600 group-hover:bg-[#536aff] transition-all duration-300"
              />
            </div>
          </div>
          
          {#if hoveredFeature === i}
            <div
              class="absolute inset-0 border-2 border-[#536aff] rounded-2xl pointer-events-none"
              transition:fade={{ duration: 150 }}
            />
          {/if}
        </div>
      {/each}
    </div>
  </div>
</section>

<style>
  /* Ensure smooth transitions */
  * {
    -webkit-tap-highlight-color: transparent;
  }

  .group:hover::after {
    opacity: 1;
  }
</style>
